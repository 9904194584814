<template>
  <div>

    <list :fs="couponFields" api="features" editLink="/admin/features" addLink="/admin/features/new"></list>
  </div>
</template>

<script>
import List from "@/components/List";
import { featureFields } from "@/config";

export default {
    data() {
        return {
            couponFields: featureFields
        };
    },
    components: {
        List
    }
};
</script>